import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import $ from 'jquery';
import HTMLReactParser from 'html-react-parser';
import jsPDF from 'jspdf'
import {PDFExport, savePDF} from '@progress/kendo-react-pdf';

function Termfinal() {
  //tutor
  const pdfExportResult = useRef(null);
  let udate_ob = new Date();
  let udate = ("0" + udate_ob.getDate()).slice(-2);
  let umonth = ("0" + (udate_ob.getMonth() + 1)).slice(-2);
  let uyear = udate_ob.getFullYear();
  let udateDisplay = uyear + "-" + umonth + "-" + udate;
    const [loadingstatus, setLoadingStatus] = useState(false);


    const [firstlastName, setFirstLastName] = useState();
    const [noteAm, setNoteAm] = useState(null);

    const [studentPhone, setStudentPhone] = useState();
    const [studentEmail, setStudentEmail] = useState();
    const [studentAddress, setStudentAddress] = useState();

    const [checkStatus, setCheckStatus] = useState(false);
    const [loadStudentStatus, setLoadStaudentStatus] = useState(false);


    const [grade, setGrade] = useState(false);

    const [centres, setCentres] = useState(null);
    const [studentData, setStudentData] = useState(null);
    const [studentGrade, setStudentGrade] = useState();
    const [studentGender, setStudentGender] = useState();
    const [year, setyear] = useState();
    const [studentID, setStudentID] = useState();
    

        const [t1,setT1] = useState('');
        const [t2,setT2] = useState('');
        const [t3,setT3] = useState('');
        const [t4,setT4] = useState('');
        const [disterms, setDisTerm] = useState(false)
        const [final,setFinal] = useState(false);
        const [cfinal,setCfinal] = useState('');
        const [allowBtn, setAllowBtn] = useState(false);
        const [createLoad, setCreateLoad] = useState(false);
        const [hideShow, setHideShow] = useState(false);
        const [notes, setNote] = useState(false)
        const [comment, setComment] = useState('');

        const [viewReport, setViewReport] = useState(null);
        const [downloadStatus, setDownloadStatus] = useState(null);
        const [centreName, setCentreName] = useState();
        const [centreAddress, setCentreAddress] = useState();
        const [gradeData, setGradeData] = useState(null)
        const [cid, setCid] = useState();


    const curDate = new Date().getFullYear();


    
    
    const checkTerms = () =>{
          if(t1 !== '' && t2==='' && t3==='' && t4==='' && cfinal===''){
              return ('Result compilation for term 1')
          }else
          if(t1 !== '' && t2 !=='' && t3==='' && t4==='' && cfinal===''){
            return ('Result compilation for term 1 & 2')
          }else
          if(t1 !== '' && t2 !=='' && t3 !=='' && t4==='' && cfinal===''){
            return ('Result compilation for term 1,2 & 3')
          }else
          if(t1 !== '' && t2 !=='' && t3 !=='' && t4 !=='' && cfinal===''){
            return ('Result compilation for term 1,2,3 & 4')
          }else if(t1 !== '' && t2 !=='' && t3 !=='' && t4 !=='' && cfinal !==''){
            return ('Final result compilation');
          }
    }

    const handleCentre = (v) =>{
      const mc = v.split("=>");
      setCentreName(mc[1]);
      setCentreAddress(mc[2]);
        setCid(mc[0]);
      axios.get('/get-user-grade?cid='+mc[0],{
        headers:{ 'Content-Type': 'application/json'}
      })
      .then((response)=>{
                 
            if(response.data.status === false){
           
                setGradeData(null)
              
            }else{
             setGradeData(response.data.status)
            }

      }) 

    }





    const handleStudentData = (sd) =>{
              if(sd === false){
                  setCheckStatus(false)
              }else
              {
                setCheckStatus(true);
              setLoadStaudentStatus(false);

              setTimeout(()=>{
                setLoadStaudentStatus(true);
              
                let stud = sd.split('=>');
             
                  let status = stud[8];

                   if(status === '1'){
                      setNoteAm('Student account is suspended. Kindly contact support for more info')
                       return;
                   }
                //s.student_id+","+s.firstname+","+s.lastname+","+s.phone+","+s.grade+","+s.email+","+s.gender+","+s.age
                setStudentEmail(stud[5]);
                setStudentPhone(stud[3]);
                setStudentGrade(stud[4]);
                setFirstLastName(stud[1]+" "+stud[2]);
                setStudentAddress(stud[7]);
                setStudentGender(stud[6])
                setStudentID(stud[0]);
                  setHideShow(true)
  
              },2000)
           

              }
              
            
    }


    const handleGrade = (gd) =>{
       if(gd === false){}else{
        setLoadingStatus(true)
         setGrade(gd)

        setLoadingStatus(true)
      
      axios.get('/all-student-centre/'+gd+'/'+cid,{
        headers: { 'Content-Type': 'application/json'}
      }).then((res)=>{
            setTimeout(()=>{
                      setLoadingStatus(false)
                  if(res.data.gradeFilter === false){
                            setStudentData(null)
                  }else
                  {
                          setStudentData(res.data.gradeFilter);
                  }
            },2000)
      })

    
      }  

    }


     const checkFinal = () =>{
          if(final !== false){
                return (
                  <button type="button" className="btn btn-primary w-xl waves-effect waves-light" disabled>
                  <i className="mdi mdi-download font-size-16"></i> Create Report
                                                </button> 
                )
          }else
          {
            return (
              <button type="button" className="btn btn-primary w-xl waves-effect waves-light" disabled>
              <i className="mdi mdi-download font-size-16"></i> Create Report
                                            </button> 
            )
          }
     }

     $(document).ready(function(){
          $("#create-report").click(function(){
               let termsfinals = 'allow';
                let tm1 = t1;
                let tm2 = t2;
                let tm3 = t3;
                let tm4 = t4;
                let tm5 = cfinal;
                let yer = year;
                let studID = studentID;
                let grad = grade;

               let dataDetails = {term1:tm1,term2:tm2,term3:tm3,term4:tm4,cfinal:tm5,studID:studID,year:yer,termsfinals:termsfinals,grade:grad}
               let base_url = 'https://termfinals-config.equationeducators.com/control_data.php';
                $.ajax({
                  url:base_url,
                  type:'POST',
                   data:dataDetails,
                   cache:false,
                   success:function(res){
                    $("#load-am").html('Fetching Result. Please wait...').show();
                    setTimeout(()=>{
                      $("#load-am").hide();
              
                      setViewReport(res);
                     },2000)
                      
                   }
                })
          })
     })


    const generateCoverPages = () => {


      pdfExportResult.current.save();
    /*  setDownloadStatus('yes')
      let doc = new jsPDF('p', 'pt', 'a2', true);
      doc.html(document.querySelector("#coverPages"),{
         callback: (pdf) =>{
                  setDownloadStatus(null)
                  pdf.save("Terms-report.pdf");
         }
      }) */
}

    const controlCheckBox = (e,dk) =>{
                    const checked = e.target.checked;
                 
               switch(dk){
                  case 'term1':
                    if(checked){
                      setT1('term1')
                      setFinal(false)
                      setAllowBtn(true)
                      setCfinal('')
                    }else
                    {
                      setT1('')
                      setFinal(false)
                      setAllowBtn(false)
                      setCfinal('')
                    }
                    break;
                  case 'term2':
                    if(checked){
                      setT2('term2')
                      setFinal(false)
                      setAllowBtn(true)
                      setCfinal('')
                    }else
                    {
                      setT2('')
                      setFinal(false)
                      setAllowBtn(false)
                      setCfinal('')
                    }
                    break;
                  case 'term3':
                    if(checked){
                      setT3('term3')
                      setFinal(false)
                      setAllowBtn(true)
                      setCfinal('')
                    }else
                    {
                      setT3('')
                      setFinal(false)
                      setAllowBtn(false)
                      setCfinal('')
                    }
                      break;
                   case 'term4':
                      if(checked){
                        setT4('term4')
                        setCfinal('')
                        setFinal(false)
                        setAllowBtn(true)
                      }else
                      {
                        setCfinal('')
                        setT4('')
                        setFinal(false)
                        setAllowBtn(false)
                      }
                  
                        break;
                   case 'final':
                        if(checked){
                           setNote(true)
                          setDisTerm(true)
                          setFinal(false)
                          setCfinal('final')
                          setT1('term1')
                          setT2('term2')
                          setT3('term3')
                          setT4('term4')
                          setAllowBtn(true)
                        }else
                        {
                          setCfinal('')
                          setT1('')
                          setT2('')
                          setT3('')
                          setT4('')
                          setNote(false)
                          setFinal(true)
                          setDisTerm(false)
                          setAllowBtn(false)
                        }
                     break;
                    
               }
              
    }

    useEffect(()=>{
      let min = 2020,
       max = new Date().getFullYear(),
      select = document.getElementById('year');
  
  for (var i = min; i<=max; i++){
      var opt = document.createElement('option');
      opt.value = i;
      opt.innerHTML = i;
      select.appendChild(opt);
  }
  
  select.value = new Date().getFullYear();

      axios.get('/centres',{
        headers: { 'Content-Type': 'application/json'}
       }).then((res)=>{
            
              setLoadingStatus(false)

              if(res.data.result === false){
                setCentres(null);
                }else
                {
                  setCentres(res.data.result); 
                }
         
          
       });

    },[])

    return (
        <div>
    
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

          <div className="row">

              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Terms / Final </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Academics</li>
                      <li className="breadcrumb-item active">Terms &amp; Final</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>


          <div className="row">
       
        <div className="col-lg-8">
         
         <div className="row">
        
         <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-4">Create Terms / Final Report</h5>
              <form className="row gy-3 gx-5 align-items-center">
                  { noteAm !== null ? '<div class="alert alert-info text-center">'+noteAm+'</div>' : null }

              <div className="col-sm-auto col-md-3">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Academic Year</label>
                  
                  <select className="form-select" id="year" onChange={(e)=>setyear(e.target.value)}>
                  <option value=''> Year </option> 
                   
                  </select>
                 
                </div>

               
                

              <div className="col-sm-auto col-md-3">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Centres</label>
                   
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=> handleCentre(e.target.value)}>
                    <option value={false}> Centres</option>
                      {centres === null ? <option disabled>No data available</option> :
                        centres && centres.map(cs=>(
                            <option key={cs.id} value={cs.center_id+"=>"+cs.centreName+"=>"+cs.centreAddress}>{ cs.centreName }</option>
                        ))}
                  </select>
                </div>



                <div className="col-sm-auto col-md-3">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Grade</label>

                 
                  
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=> handleGrade(e.target.value)} >
                    <option value={false}> Grade</option>
                     { 
                          gradeData === null ? <option disabled>No data available</option> :
                             gradeData.map(g=>(
                                <option key={g.id} value={g.grade}>{ g.grade }</option>
                            ))
                     }
                      
                  </select>

                </div>
                

                <div className="col-sm-auto col-md-3">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Student</label>
                   
                  <select className="form-select" id="autoSizingSelect" onChange={(e)=>handleStudentData(e.target.value)}>
                    <option value={false}> Student</option>
                      {studentData === null ? <option disabled>No data available</option> :
                        studentData && studentData.map(s=>(
                            <option key={s.id} value={s.student_id+"=>"+s.firstname+"=>"+s.lastname+"=>"+s.phone+"=>"+s.grade+"=>"+s.email+"=>"+s.gender+"=>"+s.home_address+"=>"+s.status}>{ s.firstname+" "+s.lastname }</option>
                        ))}
                  </select>
                </div>


                
            
              
                   

                <center>
                    { loadingstatus === false ? null :
                <div className="spinner-border text-primary m-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                          
}

                </center>
              </form>
            </div>
            {/* end card body */}
          </div>
          {/* end card */}

          </div>
          { hideShow === false ? null :
         <div className="col-lg-12">
         <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-4">Create Terms / Final Report</h5>


              { notes !== false ?
               <blockquote className="p-4 border-light border bg-warning rounded mb-4">
               <div className="d-flex">
                   <div className="me-3">
                       <i className="bx bxs-quote-alt-left text-dark font-size-24"></i>
                   </div>
                   <div>
                       <strong className="mb-0"> Creating a final report kindly confirm the following:</strong>
                       <p className="mb-0"> 1) All marks have been finalised and verified.</p>
                       <p className="mb-0">2) I accept that adjusting marks will no longer be possible after this confirmation.</p>
                       <p className="mb-0">3) The academic year for this learner will be completed and closed with the result achieved..</p>

                       <strong className="mb-0">If you wish to continue kindly click on create report</strong>
                       
                       
                   </div>
               </div>
               
           </blockquote> : null
            }

              <div className="row" style={{marginTop:-20}}>

              <div className="col-xl-2 col-sm-6">
             <div className="mt-4">
        <div>
            <div className="form-check form-checkbox-outline form-check-primary mb-3">
                { disterms !== false ?

              <input className="form-check-input" 
               type="checkbox" id="customCheckcolor1" disabled checked/>
               :
               <input className="form-check-input" 
               type="checkbox" id="customCheckcolor1" onClick={(e)=> controlCheckBox(e,'term1')} />

                }

              <label className="form-check-label" htmlFor="customCheckcolor1">
                Term 1
              </label>
            </div>
             </div>
        </div>
      </div>


      <div className="col-xl-2 col-sm-6">
             <div className="mt-4">
        <div>
            <div className="form-check form-checkbox-outline form-check-primary mb-3">
                        { disterms !== false ?

            <input className="form-check-input" 
            type="checkbox" id="customCheckcolor1" disabled checked/>
            :
            <input className="form-check-input" 
            type="checkbox" id="customCheckcolor1" onClick={(e)=> controlCheckBox(e,'term2')} />

              }


              <label className="form-check-label" htmlFor="customCheckcolor1">
                Term 2
              </label>
            </div>
             </div>
        </div>
      </div>



      <div className="col-xl-2 col-sm-6">
             <div className="mt-4">
        <div>
            <div className="form-check form-checkbox-outline form-check-primary mb-3">
                          { disterms !== false ?

              <input className="form-check-input" 
              type="checkbox" id="customCheckcolor1" disabled checked/>
              :
              <input className="form-check-input" 
              type="checkbox" id="customCheckcolor1" onClick={(e)=> controlCheckBox(e,'term3')} />

                }

              <label className="form-check-label" htmlFor="customCheckcolor1">
                Term 3
              </label>
            </div>
             </div>
        </div>
      </div>



      <div className="col-xl-2 col-sm-6">
             <div className="mt-4">
        <div>
            <div className="form-check form-checkbox-outline form-check-primary mb-3">
                              { disterms !== false ?

                  <input className="form-check-input" 
                  type="checkbox" id="customCheckcolor1" disabled checked/>
                  :
                  <input className="form-check-input" 
                  type="checkbox" id="customCheckcolor1" onClick={(e)=> controlCheckBox(e,'term4')} />

                    }

             
              <label className="form-check-label" htmlFor="customCheckcolor1">
                Term 4
              </label>
            </div>
             </div>
        </div>
      </div>



      <div className="col-xl-2 col-sm-6">
             <div className="mt-4">
        <div>
            <div className="form-check form-checkbox-outline form-check-primary mb-3">
                         
              <input className="form-check-input" 
              type="checkbox" id="customCheckcolor1" onClick={(e)=> controlCheckBox(e,'final')} />

                

              <label className="form-check-label" htmlFor="customCheckcolor1">
                Final
              </label>
            </div>
             </div>
        </div>
      </div>




      <div className="col-xl-12 col-sm-6">
             <div className="mt-4">
        <div>
            <textarea style={{resize:'none'}} onChange={(e)=> setComment(e.target.value)} className="form-control" placeholder="Addition report comment"></textarea>

             </div>
        </div>

        <br/><br/>
         { allowBtn !== false ? 
      <button type="button" className="btn btn-primary w-xl waves-effect waves-light" id="create-report" data-bs-toggle="modal" data-bs-target="#exampleModalFullscreen">
              <i className="mdi mdi-download font-size-16"></i> Create Report
                                            </button>  

                                            :
                                                createLoad === false ?

                                                   checkFinal()

                                                   :                       
                                            <button className="btn btn-primary waves-effect waves-light" disabled>

                                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                               Please Wait...</button>

         }




      </div>


     
              </div>

            </div>
            </div>

         </div>
}
      </div>
        </div>
        {/* end col */}

        <div className="col-lg-4">
        

                     { checkStatus === false ? null :

<div className="card">
            <div className="card-body">
                <div className="table-responsive">
                <h4 className="card-title mb-4">Student Information</h4>
                 { loadStudentStatus === false ?
                <center>
                    <div className="spinner-border text-primary m-1 " role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                    </center> 
                    :

                
                    <table className="table table-nowrap mb-0">

                    <tbody>
           <tr>
             <th scope="row">Full Name :</th>
             <td>{ firstlastName }</td>
           </tr>
           <tr>
             <th scope="row">Grade :</th>
             <td>{ studentGrade }</td>
           </tr>
           <tr>
             <th scope="row">Gender :</th>
             <td>{ studentGender }</td>
           </tr>
           <tr>
             <th scope="row">Mobile :</th>
             <td>{ studentPhone }</td>
           </tr>
           <tr>
             <th scope="row">E-mail :</th>
             <td>{ studentEmail }</td>
           </tr>
           <tr>
             <th >Location :</th>
             <td>{ studentAddress }</td>
           </tr>
         
          
         </tbody>
                   </table>

                              

}

         
                    </div>

                    </div>
</div>
}


                </div>
                
      </div>


      <div id="exampleModalFullscreen" className="modal fade" tabindex="-1" aria-labelledby="#exampleModalFullscreenLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-fullscreen">
                                                    <div className="modal-content">
                                                       
                                                        <div className="modal-header">
                                                            <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">{ checkTerms() }
                                                            </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body"  id="coverPages">
                                                        <PDFExport ref={pdfExportResult} margin="2cm" paperSize="auto">
                                                        <div className="row">
                                                        <div className="col-lg-2">
                                                          </div>
                                            <div className="col-lg-8">
                                             

                                            <div className="invoice-title">
                                                                                <center>
                                                                                <img src="/autheme/assets/images/eq4.png" alt="logo" height={60} />
                                                                              
                                                                                
                                                                                </center>
                                                                                
                                                                                <div className="mb-4" style={{marginTop:60}}>
                                                                                 
                                                                                <p style={{fontSize:18}}><b>Assessment Report &nbsp; {year}</b></p>
                                                                                </div>
                                                                            </div>

                                                                            <div className='row'>
                                                                    <div className='col-xl-6'>
                                                                    <div style={{float:'left'}}>
                                                                      <strong style={{fontSize:18}}>Centre Name: { centreName }</strong><br></br><br></br>
                                                                     
                                                                      <p>{ centreAddress }</p>
                                                                      </div>
                                                                    

                                                                    </div>

                                                                    <div className='col-xl-6'>
                                                                      <div style={{float:'right', fontSize:14, marginRight:20}}>
                                                                      <strong>Student Name: { firstlastName}</strong><br></br>
                                                                      <strong>Student ID: { studentID }</strong><br></br>
                                                                      <strong>Date Issue: { udateDisplay }</strong><br></br>
                                                                      <strong>Grade: { grade }</strong>
                                                                      </div>
                                                                    
                                                                    </div>
                                                                  </div>
                                                                             <hr></hr>
                                                                                        <center>
                                                                                          <div id='load-am'></div>
                                                                                        </center>
                                                                                    { viewReport === null ? null : HTMLReactParser(viewReport)}
                                                                                          
                                                                                    { comment ==='' ? null : 
                                                                                     <blockquote style={{marginTop:40}} className="p-4 border-light text-white border bg-success rounded mb-4">
                                                                                     <div className="d-flex">
                                                                                         <div className="me-3">
                                                                                             <i className="bx bxs-quote-alt-left text-white font-size-24"></i>
                                                                                         </div>
                                                                                         <div>
                                                                                         <br></br>
                                                                                             <strong className="mb-0"> Comment:</strong><br></br>
                                                                                             <p className="mb-0">{ comment }</p>
                                                                                           
                                                                                             
                                                                                         </div>
                                                                                     </div>
                                                                                     
                                                                                 </blockquote>
                                                                                    }
                                                 
                                                 


                                                  {/* end table-responsive */}
                                          
                                            </div>
                                          
                                                                                          
                                                       
                                        
                                          <div className="col-lg-2">
                                            </div>


                                            <div className="col-lg-12">
                                                            <br></br><br></br>
                                                          </div>

                                            </div>

                                            <div className="row">
                                            <div className="col-lg-2">
                                            </div>
       
      <div className="col-lg-3">
           
           <center>
            <img src="/autheme/assets/images/sign.png" alt="logo" height={70} />
            </center>
          
            <hr style={{marginTop:-20}}></hr>
              <p className='text-center'>Wayne Cox (Head of Assessment)</p>
           
          
             {/* end table-responsive */}

             <center>
               <br></br>
            <img src="/autheme/assets/images/stamp.png" alt="logo" height={150} />
            </center>

       </div>
    
       <div className="col-lg-5">
       

        
             <div className="table-responsive">
               <table className="table align-middle table-nowrap mb-0">
                 <thead className="table-light">
                   <tr>
                     
                     <th className="align-middle">Rating Code</th>
                     <th className="align-middle">Description of Competence</th>
                     <th className="align-middle">Percentage</th>
                     <th className="align-middle">Symbol</th>
                   
             
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     
                     <td className="text-center"> 7 </td>
                     <td><p>Outstanding achievement: Exceptional<br></br>
and commendable execution of the skill.</p></td>
                     <td>
                     80%-100%
                     </td>

                     <td>
                    A+
                     </td>
                    
                    
                   </tr>
                   <tr>
                     
                     <td className="text-center"> 6 </td>
                     <td><p>Meritorious achievement: Very good<br></br>
execution of the skill.</p></td>
                     <td>
                     70%-79%
                     </td>
                     <td>
                      A
                     </td>
                    
                   </tr>

                   <tr>
                     
                     <td className="text-center"> 5 </td>
                     <td><p>Substantial achievement: Good execution<br></br>
of the skill.</p></td>
                     <td>
                     60%-69%
                     </td>

                     <td>
                     B
                     </td>
                    
                    
                   </tr>

                   <tr>
                     
                     <td className="text-center"> 4 </td>
                     <td><p>Adequate achievement: Satisfactory<br></br>
progress.</p></td>
                     <td>
                     50%-59%
                     </td>
                     <td>
                    C
                     </td>
                    
                   </tr>

                   <tr>
                     
                     <td className="text-center"> 3 </td>
                     <td><p>Moderate achievement: Little progress<br></br>
and assistance is required.</p></td>
                     <td>
                     40%-49%
                     </td>
                       <td>
                   D
                     </td>
                    
                    
                   </tr>

                   <tr>
                     
                     <td className="text-center"> 2 </td>
                     <td><p>Elementary achievement: Very little<br></br>
progress and requires constant
assistance.</p></td>
                     <td>
                     30%-39%
                     </td>
                     <td>
                     E
                     </td>
                    
                    
                   </tr>

                   <tr>
                     
                     <td className="text-center"> 1 </td>
                     <td><p>Not achieved: No progress and requires<br></br>
immediate attention.</p></td>
                     <td>
                     0%-29%
                     </td>
                     <td>
                    F
                     </td>
                    
                    
                   </tr>
                
                 </tbody>
               </table>
             </div>
             {/* end table-responsive */}
           
      
       </div>
                                                          </div>
                                                          </PDFExport>
                      
                                                       
                                                        </div>
                                                       
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal">Close</button>
                                                            { downloadStatus === null ? 
                                                            <button type="button" className="btn btn-primary waves-effect waves-light" onClick={()=> generateCoverPages()}>Download Result</button>
                                                                :
                                                                <button className="btn btn-primary waves-effect waves-light" disabled>

                         <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                           Please Wait...</button>
                                                             }
                                                      
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


          </div> {/* container-fluid */}
        </div>
        {/* End Page-content */}
     
      </div>
        </div>
    )
}

export default Termfinal
