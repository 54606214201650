import React, { useState, useEffect } from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import Footer from '../../Nav/Footer'
import axios from 'axios';
import $ from 'jquery';


function PaymentStatus() {
  const currencyFormat = (num) => {
    return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }
    axios.defaults.withCredentials = true;
    const {id } = useParams();
    const [schooloption, setSchoolOption] = useState('');
  
    const [total, setTotal] = useState(0);
    const [duration, setDuration] = useState('');
    const history = useNavigate();
    const [notify, setNotify] = useState(null);

    const [durationOption, setDurationOption] = useState(false);
    const [subjectData, setSubjectData] = useState();
    const [subjectStatus, setSubjectStatus] = useState(null);
    const [msybjectStatus, setMySubjectStatus] = useState(false);
    const [overallTotal, setOverallTotal] = useState(0);
     const [isChecked, setIsChecked] = useState(false);
     const [amtSub, setAmtSub] = useState(0);
     const [totalsubTerm, setTotalSubTerm] = useState(0);
     const [lang, setLang] = useState();
     const [grdAlert, setGrdAlert] = useState(null);
     const [gradeRPackage, setGradeRPackage] = useState();
   
   
  const removeSubject = () =>{
       
  }
  
    const valtub = () =>{
        let valid = true;
          if(!schooloption.trim()){
             valid = false;
             setNotify('Please select student grade');
          }else{
            valid = true;
            setNotify('');
          }

          if(!duration.trim()){
            valid = false;
            setNotify('Please select duration for billing circle');
          }else{
            valid = true;
            setNotify('');
          }

          return valid;
    }

    const checkSelectedGrade = (e) =>{
            setSchoolOption(e.target.value);
            setDuration('');
            setTotal(0)
            setMySubjectStatus(false)
             let gradeData = e.target.value;
             if(gradeData === ''){
              setDurationOption(false);
             }else{
              setDurationOption(true);
             }

              if(e.target.value === 'R'){
                    axios.get('grade-r-packages',{
                      headers: { 'Content-Type': 'application/json'}
                    }).then((rep)=>{
                           if(rep.data.mstate === false){
                                  
                                  setGrdAlert(null);
                                  setGradeRPackage(rep.data.package);
                           }else{
                                  setGrdAlert(rep.data.mstate);
                           }
                    })
              }
           
          
    }

    const handlePrice = (e) =>{
            setTotal(0)
            setDuration(e.target.value);
               let dura = e.target.value;
                console.log(dura)
            axios.post("/school-price",
            { userduration:dura,
              schooloption:schooloption,
              lang:lang,
              grdRID:null
            
            },{
              headers: { 'Content-Type': 'application/json'}
           })
            .then((response)=>{
                
                  if(response.data.priceGrade === false){
                    setTotal(0);
                  }else{
                       
                        setTotal(response.data.priceGrade);
                  }

                  if(response.data.msubject === false){
                       setSubjectStatus('No subject for the selected grade');
                       setMySubjectStatus(true)
                  }else{
                       setSubjectData(response.data.msubject);
                       setMySubjectStatus(true);
                       
                    
                           
                  }


            })
          


    }

   const validateSubject = () =>{
          console.log(schooloption)
         axios.get('/validate-user-subject/'+id+'/'+schooloption)
         .then((response)=>{
            //setValidData(response.data)
           
             return response.data.valid;
                
              
         })
    } 

    const handlePriceSave = () =>{
            

              if(!valtub()){}else{
           
                axios.get('/validate-user-subject/'+id+'/'+schooloption)
         .then((response)=>{
            //setValidData(response.data)
           
             if(response.data.valid === 'not valid'){
              setNotify('Please select student subject');
             }else{

              const registration_status = "save";
              axios.post("/complete-registration",
              {grade:schooloption,
                total:totalsubTerm,
                bill_cycle:duration,
                registration_status:registration_status,
                studentID:id
              },{
                headers: { 'Content-Type': 'application/json'}
             }).then((response)=>{
                  if(response.data.updateStatus === true){
                    history("/student/all-student")
               } 
                })
    


             }
                
              
         })
     

          
        }
          
    }



    const handlePriceData = () => {

    //  localStorage.setItem("Package_name",grdR[2]);
     // localStorage.setItem("Package_id",grdR[1]);
   
      if(!valtub()){}else{

          let package_name, package_id;

            if(schooloption==='R'){
               package_name = localStorage.getItem("Package_name");
               package_id = localStorage.getItem("Package_id");
            }else{
              package_name = "none";
              package_id = "none";
            }

        axios.get('/validate-user-subject/'+id+'/'+schooloption) 
         .then((response)=>{ 

          if(response.data.valid === 'not valid'){
            setNotify('Please select student subject');
           }else{
      const registration_status = "complete";
      axios.post("/complete-registration",
      {
        grade:schooloption,
        total:totalsubTerm,
        bill_cycle:duration,
        registration_status:registration_status,
       studentID:id,
       packageName:package_name,
       packageID:package_id
      
      },{
        headers: { 'Content-Type': 'application/json'}
     }).then((response)=>{
           if(response.data.updateStatus === true){
               localStorage.removeItem("Package_name");
               localStorage.removeItem("Package_id");
                 
                 history("/student/all-student/complete/invoice/"+response.data.invoice);
            } 
        });

      }

      })
        
      }
    }

 

    const handleSubjectCheck = (eCheck,subjectID,price,subject) =>{
                    let mtotal;
              
                setIsChecked(true)

                   if(eCheck){
                   if(Object.keys(price).length === 0)
                        {
                          mtotal = 0;   
                       }else
                       {
                        mtotal = price; 
                      }
                    }else{
                      if(Object.keys(price).length === 0)
                      {
                        mtotal = 0;   
                     }else
                     {
                      mtotal = price; 
                    }
                    }

                     
                       
                      axios.post('/subject-booking',{
                        subjectID:subjectID,
                        price:price,
                        subject:subject,
                        student_id:id,
                        grade:schooloption
     
                     },{
                       headers: { 'Content-Type': 'application/json'}
                    }).then((response)=>{
                              setAmtSub(response.data.len);
                                let oversub = parseInt(overallTotal);
                                 let cursub = parseInt(mtotal);
                                 let generalTotal = parseInt(total);
                               
                              if(eCheck){
                                    let subTotal = oversub + cursub;
                                    setOverallTotal(subTotal);
                                      let mk = generalTotal + subTotal;
                                      setTotalSubTerm(mk)
                              }else{
                                let msubtotal = oversub - cursub;
                                   setOverallTotal(msubtotal);
                                   let mk = generalTotal - msubtotal;
                                   setTotalSubTerm(mk)
                                 }
                                  
                                   
                                 



                           
                          


                     })

                 
                  


                    
              
    }

    useEffect(()=>{
        axios.get('/get-student-data/'+id,{
          headers: { 'Content-Type': 'application/json'}
        }).then((res)=>{
             setLang(res.data.result[0].lang_intruct);
            
        })
    },[])

    const handleGradeRPackage =(details)=>{

        let grdR = details.split("=>");
         localStorage.setItem("Package_name",grdR[2]);
         localStorage.setItem("Package_id",grdR[1]);
      setTotal(0)
      setDuration('Year');
        
          
      axios.post("/school-price",
      { userduration:'Year',
        schooloption:schooloption,
        lang:lang,
        grdRID:grdR[1]
      
      },{
        headers: { 'Content-Type': 'application/json'}
     })
      .then((response)=>{
          
            if(response.data.priceGrade === false){
              setTotal(0);
            }else{
                 
                  setTotal(response.data.priceGrade);
            }

            if(response.data.msubject === false){
                 setSubjectStatus('No subject for the selected grade');
                 setMySubjectStatus(true)
            }else{
                 setSubjectData(response.data.msubject);
                 setMySubjectStatus(true);
                 
              
                     
            }


      })
    

    }
   
    return (

        <div id="layout-wrapper">
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
          
            
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title" id="title">Student Grade &amp; Registration Fee </h5>
                    <p className="card-title-desc">Kindly use the tools below to complete registration</p>
  
                    <form>
                    { grdAlert !== null ?  <div className='alert alert-info'>{ grdAlert } </div> : null }
                      { notify !== null ?  <div className='alert alert-info'>{ notify } </div> : null }
                     
                   
                      <div className="row">
                            
                      <div className="col-md-6">
                            <div className="form-floating mb-3">
                              <select onChange={ checkSelectedGrade } className="form-select" name="applying_year" id="floatingSelectGrid" aria-label="Floating label select example">
                              <option value=''>Select Grade</option>
                                <option value="R">R</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                              </select>
                              <label htmlFor="floatingSelectGrid">Select Student Grade</label>
                             
                            </div>
                          </div>

                             { schooloption === 'R' ?
                           <div className="col-md-6">
                            <div className="form-floating mb-3">
                              <select onChange={(e)=> handleGradeRPackage(e.target.value)} className="form-select" name="applying_year" id="floatingSelectGrid" aria-label="Floating label select example">
                              <option value=''>Grade R Package</option>
                                { gradeRPackage && gradeRPackage.map(({r_package,price,id,pid},index)=>{
                                       return(
                                         <option key={index} value={price+"=>"+pid+"=>"+r_package}>{ currencyFormat(parseInt(price))+" ("+r_package+")"}</option>
                                       )
                                })} 
                              </select>
                              <label htmlFor="floatingSelectGrid">Select Package</label>
                             
                            </div>
                          </div>
                          :  
  
                        
                    
                         <div className="col-md-6">
                          <div className="form-floating mb-3">
                           <select className="form-select" onChange={handlePrice} name="applying_year" id="floatingSelectGrid" aria-label="Floating label select example">
                           <option value="">Payment Plan </option>
                             
                              { durationOption === false ? <option disabled>No data available</option> :
                              <>
                          
                             <option value='Termly'>Termly </option>
                             <option value='Yearly'>Yearly </option>
                             </>
                             }
                            
                           </select>
                           <label htmlFor="floatingSelectGrid">Select Payment Option</label>
                          
                         </div>
                    </div>
}
                           
                          <div className="col-md-12">
                          <br />  
                            {
                            
                           
                            msybjectStatus === false ? null :
                            subjectStatus !== null ? <div className='alert alert-info'>{ subjectStatus }</div>
                            :
                         <div>
       <h4 className="card-title mb-4"> Subject For Grade { schooloption }</h4>
        <div className="table-responsive">
           <p>We have elective and compulsory subject. Kindly select the available subject below </p>
          
          <table className="table align-middle table-nowrap mb-0">
            <thead className="table-light">
              <tr>
                <th style={{width: '20px'}}>
                  
                </th>
                <th className="align-middle">Subject ID</th>
                <th className="align-middle">Subject Name</th>
                <th className="align-middle">Category</th>
                <th className="align-middle">Language of Instructor</th>
                <th className="align-middle">Price</th>
               
               
              </tr>
            </thead>
            <tbody>
              
              { subjectData && subjectData.map(({ subject, subject_id, price, subject_cate, lang }, index) => {
                  return(
                         
                      <tr key={index}>
                      <td>
                        <div className="form-check font-size-16">
                          <input className="form-check-input" type="checkbox"
                           id="transactionCheck02" 
                           value={subject}
                           name={subject}
                      
                           onChange={(e)=> handleSubjectCheck(e.target.checked,subject_id,price,subject)}  />
                          <label className="form-check-label" htmlFor="transactionCheck02" />
                 
                        </div>
                      </td>
                      <td><div className="text-body fw-bold">#{ subject_id }</div> </td>
                      <td>{ subject }</td>
                      <td>{ subject_cate }</td>
                      <td>{ lang }</td>
                      <td>
                        { price === null ? currencyFormat(0) : price === '' ? currencyFormat(0) : currencyFormat(parseInt(price))}
                      </td>
                      </tr>
                  );
              })

              }
              
               </tbody>
          </table>
      
        </div>
        </div>
                        
                        
                        }
                            </div>

                          
                   
                      </div>


                      <hr></hr>
                      <div className="col-md-12">
                      <table className="table project-list-table table-nowrap align-middle">
                          <tbody>
                            <tr>
                              <td></td>
                              <td></td>
                              <td colSpan={1} className="border-0 text-end">
                              {duration} Fee
                              </td>
                              <td className=" text-end">
                              { total === 0 ? ('0.00') : currencyFormat(parseInt(total))}
                              </td>
                            </tr>

                            <tr>
                            <td></td>
                              <td></td>
                              <td colSpan={1} className=" text-end">
                               Fee of { amtSub } Subject
                              </td>
                              <td className=" text-end">
                              { total === 0 ? ('0.00') : currencyFormat(parseInt(overallTotal))}
                              </td>
                            </tr>

                            <tr>
                            <td></td>
                              <td></td>
                              <td colSpan={1} className=" text-end">
                                <strong>Total</strong>
                              </td>
                              <td className=" text-end">
                              { total ===  0 ? ('0.00') : currencyFormat(parseInt(totalsubTerm))}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                     
                      <br/><br/><br/>
                      </div>
                          
                   
                     
                      <div style={{float: 'left'}}>
                        <button type="button" onClick={()=>handlePriceSave()} className="btn btn-primary w-md submit">Complete &amp; Save</button>
                  
                      </div>
                      <div style={{float: 'right'}}>
                        <button type="button" onClick={()=>handlePriceData()} className="btn btn-primary w-md submit">Complete &amp; Pay Now</button>
                  
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
             
            
          
          </div>
          </div>
         <Footer />
        </div>
      </div>
       
       
    )
}

export default PaymentStatus
